import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        return (
            <Layout>
                <Nav
                    pageTitle={'404 Page'}
                    title={''}
                    description={''}
                    keywords={''}
                ></Nav>
                <Intro title="Page not found" description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>

                <div className="main-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="blog-container">
                                    Page Not Found
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;